/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { EstablishmentsContext } from '../../../../context/Establishments';
import { GlobalBasketContext } from '../../../../context/GlobalBasketContext';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import callGtmEvent from '../../../../utilities/gtm';
import Checkbox from '../../../UI/Form/Checkbox';
import PickUp from './PickUp';

const DELIVERY_EVENT_NAME = {
  0: 'Доставка',
  1: 'Самовывоз',
};

export default function DeliveryType() {
  const { control, watch, setValue, getValues } = useFormContext();
  const establishments = useContext(EstablishmentsContext);
  const { setRestInfo, items: basketItems, updateBranch } = useContext(
    GlobalBasketContext
  );
  const deliveryTypeWatcher = watch('delivery_type');
  const currentId = useRef(utilities.basket.getEstablishmentId());
  const firstMount = useRef(true);
  const currentEstablishment = useRef(
    establishments.find(({ id }) => id === currentId.current)
  );

  // TODO Филиалы ресторана. Неккоректное условие отображения селектов
  // В случае, если всего один филиал - устанавливаем его, как активный
  useEffect(() => {
    if (currentEstablishment.current?.branches?.length === 1) {
      const branchId = currentEstablishment.current.branches[0].id;
      setValue('branch', branchId);
      updateBranch(branchId);
    }
  }, [currentEstablishment]);

  useEffect(() => {
    const resetBasketDeliveryAddress = async () => {
      const response = await utilities.api.post(
        routes.apiSwagger.addShoppingCardAddress(),
        {
          point: null,
        }
      );
      const { items, ...rest } = response;
      setRestInfo({ ...rest });
    };

    const addBasketDeliveryAddress = async () => {
      const response = await utilities.api.post(
        routes.apiSwagger.addShoppingCardAddress(),
        {
          point: getValues('point'),
        }
      );
      const { items, ...rest } = response;
      setRestInfo({ ...rest });
    };

    const sendGtmEvent = async () => {
      const categories = basketItems.map(({ category_id }) => category_id);
      const categoryNames = await Promise.all(
        categories.map((id) =>
          utilities.api.get(routes.apiSwagger.dishCategoryInfo(id))
        )
      ).then((categoriesInfos) => {
        return categoriesInfos.reduce((acc, { id, name }) => {
          acc[id] = name;
          return acc;
        }, {});
      });
      callGtmEvent(
        'add_shipping_info',
        basketItems.map((item) => ({
          item_name: item.title,
          item_id: item.item_id,
          price: item.price,
          currency: 'RUB',
          quantity: item.count,
          item_category: categoryNames[item.category_id],
        })),
        {
          shipping_tier: DELIVERY_EVENT_NAME[deliveryTypeWatcher],
        }
      );
    };

    if (!firstMount.current) {
      if (deliveryTypeWatcher === '1') {
        // TODO Для чего нужен был reset?
        // resetBasketDeliveryAddress();
      } else if (
        deliveryTypeWatcher === '0' &&
        getValues('point') !== undefined
      ) {
        addBasketDeliveryAddress();
      }
    }
    if (!firstMount.current) {
      sendGtmEvent();
    }
  }, [deliveryTypeWatcher]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <>
      <Controller
        control={control}
        name="delivery_type"
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <>
            <Checkbox
              label={utilities.translate('Доставка по адресу')}
              type="radio"
              value="0"
              controlledChecked={Boolean(field.value === '0')}
              error={fieldState.error}
              onValueChange={(value) => {
                field.onChange(value);
              }}
              name={field.name}
            />
            {currentEstablishment.current?.branches?.length === 1 && (
              <Checkbox
                label={`${utilities.translate('Самовывоз по адресу')} ${
                  currentEstablishment.current?.branches[0]?.address?.street
                }`}
                type="radio"
                value="1"
                controlledChecked={Boolean(field.value === '1')}
                error={fieldState.error}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
                name={field.name}
              />
            )}
          </>
        )}
      />
      {currentEstablishment.current?.branches?.length > 1 && (
        <PickUp currentEstablishment={currentEstablishment.current} />
      )}
    </>
  );
}
