/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import debounce from 'debounce-promise';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EstablishmentsContext } from '../context/Establishments';
import routes from '../routes';
import utilities from '../utilities';
import useLocalStorageChange from './useLocalStorageChange';

export default function useBasket() {
  const establishments = useContext(EstablishmentsContext);
  const shoppingCard = useLocalStorageChange({
    field: utilities.basket.SHOPPING_CARD_KEY,
  });
  const basketDishes = useLocalStorageChange({
    field: utilities.basket.BASKET_DISHES_KEY,
  });
  const basketTotal = useLocalStorageChange({
    field: utilities.basket.BASKET_TOTAL_KEY,
  });
  const [items, setItems] = useState([]);
  const [restInfo, setRestInfo] = useState({});
  const firstMount = useRef(true);

  const calculateCount = (object) => {
    return Object.values(object).reduce((acc, item) => {
      if (typeof item === 'object') {
        return acc + calculateCount(item);
      }
      return acc + +item;
    }, 0);
  };

  const data = useMemo(
    () => ({
      totalCount: calculateCount(basketDishes || {}),
      totalPrice: basketTotal,
      items,
      basketDishes,
    }),
    [basketDishes, basketTotal, items]
  );

  const updateBranch = async (id) => {
    // TODO Где то не долетает id. Надо искать вызовы и фиксить передачу параметров.
    if (id) {
      const response = await utilities.api.post(
        routes.apiSwagger.updateBasketBranch(),
        {
          point: null,
          branch_id: id,
        }
      );
      const { items: responseItems, ...rest } = response;
      setItems(responseItems);
      setRestInfo({ ...rest });
    }
  };

  const createShoppingCard = async (dish) => {
    const response = await utilities.api.postMobile(
      routes.apiSwagger.createShoppingCard(),
      {
        dish_list: [
          {
            dish: dish.dish_id,
            count: 1,
            dish_property_value: dish.property_selected || null,
          },
        ],
        establishment: dish.establishment_id,
      }
    );
    const { items: responseItems, ...rest } = response;
    setItems(responseItems);
    setRestInfo({ ...rest });
    utilities.basket.setTotal(response.dish_amount);
    utilities.basket.setCardKeyId(response.id);
    utilities.basket.setDishes(
      response?.items?.reduce((acc, item) => {
        if (!acc[item.item_id]) {
          if (item.dish_property_value) {
            acc[item.item_id] = {};
            acc[item.item_id][item.dish_property_value] = item.count;
          } else {
            acc[item.item_id] = item.count;
          }
        }
        return acc;
      }, {})
    );
    // utilities.basket.setDishes({
    //   [`${dish.id}`]: {
    //     [`${dish.property_selected}`]: 1,
    //   },
    // });
    utilities.basket.setEstablishmentId(
      dish.establishment_id || dish.establishment?.id
    );
    if (!rest.branch_id) {
      const establishment = establishments.find(
        ({ id }) => id === dish.establishment_id
      );
      updateBranch(establishment.branches[0].id);
    }
  };

  const setCount = (initial, { count, direction }) => {
    if (initial) {
      if (direction > 0) {
        if (count === 1) {
          return +initial + count;
        }
        return count;
      }
      if (direction < 1) {
        if (count === 1) {
          return +initial - count;
        }
        return count;
      }
      return count;
    }
    return 1;
  };

  const updateShoppingCard = async (dish, options) => {
    const { dish_id: dishId } = dish;
    const dishProperty =
      dish.property_selected || dish.dish_property_value || null;
    let dishCount;
    if (dish.property_selected || dish.dish_property_value) {
      const currentAvailable = utilities.basket.getDishes()[dishId];
      if (currentAvailable) {
        dishCount = currentAvailable[dishProperty] || 0;
      } else {
        dishCount = 0;
      }
    } else {
      dishCount = utilities.basket?.getDishes()?.[dishId] || 0;
    }
    const response = await utilities.api.post(routes.apiSwagger.updateOrder(), {
      cart_dish: {
        dish: dishId,
        count: setCount(dishCount, options),
        dish_property_value: dishProperty,
      },
    });
    const { items: responseItems, ...rest } = response;
    setItems(responseItems);
    setRestInfo({ ...rest });
    utilities.basket.setTotal(response.dish_amount);
    utilities.basket.setDishes(
      response?.items?.reduce((acc, item) => {
        if (!acc[item.item_id]) {
          if (item.dish_property_value) {
            acc[item.item_id] = {};
            acc[item.item_id][item.dish_property_value] = item.count;
          } else {
            acc[item.item_id] = item.count;
          }
        } else if (item.dish_property_value) {
          acc[item.item_id][item.dish_property_value] = item.count;
        }
        return acc;
      }, {})
    );
    // utilities.basket.setDishes({
    //   ...utilities.basket.getDishes(),

    // })
    // utilities.basket.setDishes({
    //   ...utilities.basket.getDishes(),
    //   [`${dishId}`]: response.items.find(({ item_id }) => item_id === dishId)
    //     .count,
    // });
  };

  const update = async (dish, options = { count: 1, direction: 1 }) => {
    if (!utilities.basket.getCardKeyId()) {
      await createShoppingCard(dish);
    } else {
      await updateShoppingCard(dish, options);
    }
  };

  const removeDish = async ({ dish_property_value, dish_id }) => {
    const response = await utilities.api.post(routes.apiSwagger.updateOrder(), {
      cart_dish: {
        dish: dish_id,
        count: 0,
        dish_property_value,
      },
    });
    utilities.basket.removeDish(dish_id, dish_property_value);
    utilities.basket.setTotal(response.dish_amount);
    const { items: responseItems, ...rest } = response;
    setItems(responseItems);
    setRestInfo({ ...rest });
  };

  const updateProperty = async ({ count, dish_id, property_id }) => {
    const response = await utilities.api.post(routes.apiSwagger.updateOrder(), {
      cart_dish: {
        dish: dish_id,
        count,
        dish_property_value: property_id,
      },
    });
    const { items: responseItems, ...rest } = response;
    setItems(responseItems);
    setRestInfo({ ...rest });
  };

  const isSameEstablishment = useCallback((id) => {
    const establishmentId = utilities.basket.getEstablishmentId();
    if (!establishmentId) return true;
    return establishmentId.toString() === id.toString();
  }, []);

  const resetBasket = useCallback(() => {
    utilities.basket.reset();
  }, []);

  const debounceUpdateCallback = debounce(update, 150, { leading: false });
  const debounceRemoveCallback = debounce(removeDish, 200, { leading: false });
  const debounceRemove = useCallback(async (dish) => {
    debounceRemoveCallback(dish);
  }, []);
  const debounceUpdate = useCallback(
    async (dish, direction) => {
      await debounceUpdateCallback(dish, direction);
    },
    [shoppingCard, establishments]
  );

  useEffect(() => {
    const fetchShoppingCardInfo = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.getShoppingCardInfo(shoppingCard)
      );
      const { items: responseItems, ...rest } = response;
      setItems(responseItems);
      setRestInfo({ ...rest });
      utilities.basket.setTotal(response.dish_amount);
    };
    if (shoppingCard) {
      fetchShoppingCardInfo();
    }
  }, []);

  useEffect(() => {
    if (!firstMount.current) {
      if (items?.length === 0) {
        resetBasket();
      }
    }
  }, [items]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return {
    isSameEstablishment,
    remove: debounceRemove,
    update: debounceUpdate,
    updateProperty,
    reset: resetBasket,
    setRestInfo,
    updateBranch,
    ...data,
    ...restInfo,
  };
}
