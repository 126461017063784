/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect, useCallback } from 'react';
import debounce from 'debounce-promise';
import cn from 'classnames';

export default function Autocomplete({
  options,
  asyncCallback,
  asyncCallbackDeps = [],
  onChange,
  initialValue,
  holderStyles,
  error,
  label,
  clearCallback,
}) {
  const inputRef = useRef();
  const [value, setValue] = useState(initialValue || {});
  const [isActive, setActive] = useState(false);
  const isMounted = useRef(false);

  const holderClasses = cn('autocomplete', {
    'autocomplete--error': error,
  });

  const debounceUpdate = debounce(asyncCallback, 500, { leading: false });
  const debounceLoadOptions = useCallback(
    (loadString) => debounceUpdate(loadString),
    [...asyncCallbackDeps]
  );

  const id = useRef(`autocomplet-${Math.random().toString(16)}`);

  const handleChange = () => {
    if (clearCallback) {
      clearCallback(false);
    }

    if (inputRef.current.value.length === 0) {
      setActive(false);
    } else {
      debounceLoadOptions(inputRef.current.value);
      setActive(true);
    }
  };

  const handleBlur = ({ relatedTarget }) => {
    /*
      TODO Костыль
      Проблема с тач событием на айфоне. Как будто бы клик на:
      onClick={() => handleOptionSelect(option)}
      
      срабатывает позже, чем:
      onChange={handleChange}
      
      из-за этого окно закрывается раньше, чем регается информация об адресе
    */
    setTimeout(() => {
      if (!relatedTarget?.classList.contains('autocomplete-option')) {
        setActive(false);
      }
    }, 100);
  };

  const handleOptionSelect = (option) => {
    setValue(option);
    setActive(false);
  };

  useEffect(() => {
    if (isMounted.current) {
      onChange(value);
      if (value.label) {
        inputRef.current.value = value.label;
      }
    }
  }, [value]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div style={holderStyles} className={holderClasses}>
      {label && (
        <label htmlFor={id.current} className="autocomplete-label">
          {label}
        </label>
      )}
      <input
        autoComplete="nope"
        ref={inputRef}
        id={id.current}
        defaultValue={value.label}
        onFocus={() => setActive(true)}
        className="form__input"
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {error && <span className="autocomplete__error-text">{error}</span>}
      {options.length && isActive ? (
        <ul className="autocomplete-options">
          {options.map((option) => {
            return (
              <li key={option.value}>
                <button
                  onClick={() => handleOptionSelect(option)}
                  className={cn('autocomplete-option', {
                    'autocomplete-option--active': option.value === value.value,
                  })}
                  type="button"
                >
                  {option.label}
                </button>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
}
