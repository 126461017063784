/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import routes from '../routes';
import utilities from '../utilities';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Section from '../components/Section/Section';
import useWindowSize from '../hooks/useResize';
import BlockSkeleton from '../components/Skeleton/Block';
import OrderedList from '../components/Account/Orders/OrderedList';
import {
  AccountHistoryProvider,
  AccountHistoryContext,
} from '../context/Account/History';
import ModalComponent from '../components/Modal';
import Title from '../components/Title/Title';

export default function OrdersHistory() {
  // const [orders, setOrders] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const queryValue = searchParams.get('order');

  const orderIsSuccess = queryValue === 'success';
  const orderHasError = queryValue === 'error';

  const [showOrderSuccessModal, setShowOrderSuccessModal] = useState(
    orderIsSuccess || orderHasError
  );

  const { isPhone } = useWindowSize();

  useEffect(() => {
    if (!showOrderSuccessModal) {
      searchParams.delete('order');
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [showOrderSuccessModal]);

  // const fetchAllOrdes = async () => {
  //   const response = await utilities.api.get(routes.apiSwagger.allOrders());
  //   setOrders(response);
  // };
  // useEffect(() => {
  //   fetchAllObrdes();
  // }, []);
  return (
    <>
      <Breadcrumbs />
      <AccountHistoryProvider url={routes.apiSwagger.allOrders()} limit={false}>
        <AccountHistoryContext.Consumer>
          {([orders]) => (
            <Section>
              {!orders ? (
                <BlockSkeleton width="100%" height="100" count={5} gap={10} />
              ) : (
                <div className="history__table">
                  {!isPhone && (
                    <div className="history__row history__row--head">
                      <div className="history__id">{`${utilities.translate(
                        'Заказ'
                      )} №`}</div>
                      <div className="history__date">
                        {utilities.translate('Дата')}
                      </div>
                      <div className="history__cost">
                        {utilities.translate('Стоимость')}
                      </div>
                      <div className="history__status">
                        {utilities.translate('Статус')}
                      </div>
                    </div>
                  )}
                  <OrderedList isPhone={isPhone} history={orders} />
                </div>
              )}
            </Section>
          )}
        </AccountHistoryContext.Consumer>
      </AccountHistoryProvider>
      <ModalComponent state={[showOrderSuccessModal, setShowOrderSuccessModal]}>
        {orderIsSuccess && <Title>Заказ успешно оформлен!</Title>}
        {orderHasError && <Title>Ошибка при оформлении заказа</Title>}
      </ModalComponent>
    </>
  );
}
