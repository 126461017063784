/* eslint-disable no-unused-vars */
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GlobalBasketContext } from '../../../../context/GlobalBasketContext';
import utilities from '../../../../utilities';
import Checkbox from '../../../UI/Form/Checkbox';
import Select from '../../../UI/Form/Select';

export default function PickUp({ currentEstablishment }) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { updateBranch } = useContext(GlobalBasketContext);
  const watcher = useWatch({
    control,
    name: ['delivery_type', 'branch'],
  });

  const options = useMemo(() => {
    return [
      { id: 0, address: { street: 'Выбрать адрес ресторана' } },
      ...currentEstablishment.branches,
    ].map((branch) => ({
      label: branch.address.street,
      value: branch.id,
      key: nanoid(),
    }));
  }, []);

  const defaultSelectValue = useMemo(() => {
    if (watcher[1]) {
      const initialBranch = options.find(({ value }) => value === watcher[1]);
      return {
        label: initialBranch.label,
        value: initialBranch.value,
      };
    }
    return null;
  }, []);

  const [controllingValue, setControllingValue] = useState(
    defaultSelectValue || options[0]
  );

  useEffect(() => {
    if (watcher[0] === '0') {
      setControllingValue(options[0]);
    }
  }, [watcher[0]]);

  const updatePickupBranch = async (id) => {
    // TODO Почему null может упасть?
    if (id) {
      await updateBranch(id);
    }
  };

  return (
    <Controller
      name="branch"
      control={control}
      render={({ field }) => (
        <Select
          keyAsKey
          options={options}
          controllingValue={controllingValue}
          contoll
          name="branch"
          triggerOnControllingChange={false}
          className="delivery-pickup-branches-select"
          size="none"
          onChange={(object) => {
            setValue('delivery_type', '1');
            field.onChange(object.value);
            setControllingValue(object);
            updatePickupBranch(object.value);
          }}
        />
      )}
    />
  );
}
