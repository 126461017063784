import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useWindowSize from '../../../../hooks/useResize';
import Button from '../../../Button/Button';
import Modal from '../../../Modal';
import Section from '../../../Section/Section';
import utilities from '../../../../utilities';

export default function AnotherAddress({ locations, errorMessage }) {
  const { isPhone } = useWindowSize();
  const [isOpen, setOpen] = useState(false);
  const { setValue } = useFormContext();

  const generateAddress = ({ street, house_number: house }) => {
    return `ул. ${street} д. ${house}`;
  };

  return (
    <>
      <Button
        className="delivery-address-button"
        size={isPhone ? 'full' : 'medium'}
        type="button"
        onClick={() => setOpen(true)}
      >
        {utilities.translate('Выбрать адрес')}
      </Button>
      <Modal state={[isOpen, setOpen]}>
        <Section.Title>{utilities.translate('Мои адреса')}</Section.Title>
        {locations && locations.length > 0 && (
          <ul className="basket-locations-list">
            {locations.map((location) => (
              <li key={location.id}>
                <div className="address-item">
                  <span className="address-item__title">{location.name}:</span>
                  <span className="address-item__address">
                    {generateAddress(location)}
                  </span>
                </div>
                <Button
                  type="buttom"
                  className="basket-location-button"
                  modifier="text"
                  onClick={() => {
                    setValue('helpers_address', location);
                    setOpen(false);
                  }}
                >
                  {utilities.translate('Выбрать этот адрес')}
                </Button>
              </li>
            ))}
          </ul>
        )}
        {errorMessage && (
          <span className="basket-location--error">{errorMessage}</span>
        )}

        <Button
          onClick={() => {
            setValue('helpers_address_another', true);
            setOpen(false);
          }}
          type="button"
          size="medium"
          align="start"
        >
          {utilities.translate('Другой')}
        </Button>
      </Modal>
    </>
  );
}
